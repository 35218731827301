import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Button, Paper } from '@mui/material';

import Page from '../components/Page';
import Logo from '../components/Logo';
import useResponsive from '../hooks/useResponsive';

// Styled components
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const ContentStyle = styled(Paper)(({ theme }) => ({
  maxWidth: 600,
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

function NeedTopUpPage() {
  const smUp = useResponsive('up', 'sm');

  return (
    <Page title="Top Up Needed">
      <RootStyle>
        <Box sx={{ maxWidth: 200, marginBottom: 5 }}>
          <Logo />
        </Box>
        <ContentStyle elevation={3}>
          <Typography variant="h4" gutterBottom>
            Oops! You've run out of scans.
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            It looks like you need to top up your account to continue enjoying our services. Check out our pricing plans to find one that suits your needs.
          </Typography>
          <Button variant="contained" color="primary" size="large" component={RouterLink} to="/pricing">
            View Pricing Plans
          </Button>
        </ContentStyle>
      </RootStyle>
    </Page>
  );
}

export default NeedTopUpPage;
