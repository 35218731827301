// src/pages/LoginForm.js
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment, Snackbar, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import metaMaskLogin from '../../../utils/metamaskLogin';

export default function LoginForm() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    const values = methods.getValues();
    const formData = {
      username: values.email,
      password: values.password,
    };

    try {
      const responseLogin = await fetch('https://solidity-scanner.onrender.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      const dataLogin = await responseLogin.json();
      if (dataLogin.success === true) {
        localStorage.setItem('token', dataLogin.token);
        navigate('/dashboard/app', { replace: true });
      } else {
        setOpen(true);
      }
    } catch (e) {
      setOpen(true);
    }

    const response = await fetch('https://solidity-scanner.onrender.com/api/checkAuth', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      credentials: 'include',
    });

    const data = await response.json();
    if (data.authenticated) {
      console.log('good', data);
    } else {
      console.log('bad', data);
      localStorage.removeItem('token');
      setOpen(true);
    }
  };

  const handleMetaMaskLogin = async () => {
    const success = await metaMaskLogin();
    if (success) {
      navigate('/dashboard/app', { replace: true });
    } else {
      setOpen(true);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [navigate]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <RHFCheckbox name="remember" label="Remember me" />
          <Link variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
        <Button fullWidth size="large" variant="contained" onClick={handleMetaMaskLogin} sx={{ mt: 2 }}>
          Login with MetaMask
        </Button>
      </FormProvider>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Invalid Login!"
      />
    </>
  );
}
