import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import withAuthentication from './sections/auth/withAuthentication';
import './output.css';

// ----------------------------------------------------------------------

function App() {

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}

export default withAuthentication(App); // Wrap the App component with the authentication HOC
