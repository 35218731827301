// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'home',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'my reports',
    path: '/dashboard/my-reports',
    icon: getIcon('eva:grid-outline'),
  },
  {
    title: 'Pricing',
    path: '/pricing',
    icon: getIcon('eva:grid-outline'),
  },
  {
    title: localStorage.getItem('token') ? 'logout' : 'login',
    path: localStorage.getItem('token') ? '/login' : '/dashboard/app',
    icon: getIcon('eva:person-outline'),
  }
];

export default navConfig;
