import React from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BugReportIcon from '@material-ui/icons/BugReport';
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  headCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '16px',
    padding: theme.spacing(2),
  },
  bodyCell: {
    fontSize: '14px',
    padding: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));

const ExTable = ({ results }) => {
  const classes = useStyles();

  const getSeverityColor = (category) => {
    if (category.toLowerCase().includes('high')) return 'error';
    if (category.toLowerCase().includes('medium')) return 'warning';
    return 'success';
  };

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table className={classes.table} aria-label="vulnerability table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>Line No.</TableCell>
            <TableCell className={classes.headCell}>Vulnerability</TableCell>
            <TableCell className={classes.headCell}>Solution</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results?.result?.map((error, idx) => (
            <TableRow key={idx}>
              <TableCell className={classes.bodyCell}>
                <Chip
                  label={error.line_number}
                  color="primary"
                  size="small"
                  className={classes.chip}
                />
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Box className={classes.categoryTitle}>
                  <ErrorOutlineIcon className={classes.icon} color={getSeverityColor(error.category)} />
                  <Typography variant="subtitle1">{error.category}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <BugReportIcon className={classes.icon} color="action" />
                  <Typography variant="body2">{error.issue}</Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Box display="flex" alignItems="center">
                  <BuildIcon className={classes.icon} color="action" />
                  <Typography variant="body2">{error.potential_solution}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExTable;