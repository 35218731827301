// src/hooks/useAvailableScans.js
import { useEffect, useState } from 'react';

const useAvailableScans = (token) => {
  const [availableScans, setAvailableScans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAvailableScans = async () => {
      try {
        const response = await fetch('https://solidity-scanner.onrender.com/availableScans', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Assuming the token is passed as a prop to your hook
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('data is', data)
        setAvailableScans(data.availableScans);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableScans();
  }, [token]); // Rerun the effect if the token changes

  return { availableScans, loading, error };
};

export default useAvailableScans;
