// src/utils/metaMaskLogin.js
import { Web3 } from 'web3';
import axios from 'axios';

const alchemyUrl = 'https://eth-mainnet.g.alchemy.com/v2/sXaHCXrSs9cFUVZcx7sfWVLnv-2wcLtq';
const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(alchemyUrl));

const metaMaskLogin = async () => {
    try {
      // Request account access if needed
     const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  
      // Get the user's Ethereum address
      console.log("MetaMask accounts:", accounts)
      const address = accounts[0];
      console.log("MetaMask address:", address);
  
      // Create a message for the user to sign
      const message = `Login to the site with address: ${address}`;
      console.log('message:', message);
  
      // Request the user to sign the message with MetaMask
      const signature =  await window.ethereum.request({
        method: "personal_sign",
        params: [message, address],
      })
  
      // Send the address and signature to the backend for verification
      const response = await axios.post('https://solidity-scanner.onrender.com/api/auth/metamask-login', {
        address,
        signature
      });
  
      // Handle the response from the server
      if (response.data.success) {
        // Store the token in local storage
        localStorage.setItem('token', response.data.token);
        console.log('Login successful');
        return true;
      } 
    console.error('Login failed:', response.data);
    return false;
      
    } catch (error) {
      console.error('MetaMask login error:', error);
      return false;
    }
  };
  
export default metaMaskLogin;
