import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: 30,
  },
  icon: {
    fontSize: 12,
  },
  noIssues: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.success.main,
  },
}));

function IssueList({ issues }) {
  const classes = useStyles();

  if (issues.length === 0 || issues[0] === '') {
    return (
      <Typography variant="body2" className={classes.noIssues}>
        <CheckCircleOutlineIcon className={classes.icon} style={{ marginRight: 8 }} />
        No issues found
      </Typography>
    );
  }

  return (
    <List className={classes.root} dense>
      {issues.map((issue, index) => (
        <ListItem key={index} className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <FiberManualRecordIcon className={classes.icon} color="action" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">{issue}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default IssueList;