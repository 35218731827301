import { faker } from '@faker-js/faker';
import { DropzoneArea } from 'material-ui-dropzone';
import { useEffect, useReducer, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

// @mui
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Container, Typography, Card, CardHeader, Box, LinearProgress, TextField, Button } from '@mui/material';
// components
import Loader from '../components/Loading';
import { useScanResults } from '../hooks/useScanResults';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { compareVersions, hasBlockTimestamp, hasWithdrawFunctionWithModifier } from '../utils/securityStandards';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import useAvailableScans from '../hooks/useAvailableScans';
import NeedTopUpPage from './NeedTopUpPage';
import { userAtom } from '../state';
import withAuthentication from '../sections/auth/withAuthentication';


// ----------------------------------------------------------------------

function DashboardApp() {
  const theme = useTheme();
  const [fileUploaded, setFileUploaded] = useState('');
  const [fileName, setFileName] = useState('')
  // useReducer
  const [state, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
    isMounted: false,
    isDropzone: false,
    high: 8,
    low: 0,
    medium: 0,
    info: 0,
  });

  const { scan, loading, results, error, reports } = useScanResults();
  const token = localStorage.getItem('token');
  const {availableScans} = useAvailableScans(token);
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);

  console.log('availableScans', availableScans);

  useEffect(() => {
    let desiredVersion;
    let comparisonResult;
    if (Object.keys(results).length > 0) {
      console.log(results.length > 0);
      console.log('fileUploaded', fileUploaded);
      // Need to add an object here each key will correspond to a category in the security standard
      const inputString = fileUploaded;
      const regex = /pragma solidity ([><=]*\d+\.\d+\.\d+);/;
      const match = inputString.match(regex);

      if (match && match.length > 1) {
        const solidityVersion = match[1];
        const packageName = 'solc';
        const url = `https://registry.npmjs.org/${packageName}/latest`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            desiredVersion = data.version;
            comparisonResult = compareVersions(solidityVersion, desiredVersion);
            console.log(`The latest version of Solidity is ${desiredVersion}.`);
            console.log(`The Solidity version is ${solidityVersion}.`);
            console.log(`The desired version is ${desiredVersion}.`);
            console.log(`The comparison result is ${comparisonResult}.`);
          })
          .catch((error) => {
            console.error(`Error fetching the latest version of Solidity: ${error.message}`);
          });
      } else {
        console.log('Solidity version not found in input string.');
      }

      const architectureAndDesign = {
        name: 'Architecture and Design',
        issues: [comparisonResult ? 'The Solidity version is up to date' : 'The Solidity version is not up to date'],
      };

      const accessControl = {
        name: 'Access Control',
        issues: [
          hasWithdrawFunctionWithModifier(fileUploaded)
            ? 'Withdraw function should not have whenNotPaused modifier - The circuit breaker mechanism should not prevent users from withdrawing their funds.'
            : 'No circuit breaker violation found',
        ],
      };

      const blockchainData = {
        name: 'Blockchain Data',
        issues: [hasBlockTimestamp(fileUploaded)],
      };
      navigate('/dashboard/report', { state: { results, architectureAndDesign, accessControl, blockchainData } });
    }
  }, [results]);

  if (availableScans === 0) {
    return <NeedTopUpPage />;
  }



  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: '50px' }}>
          Hi, {user.username}
        </Typography>

        <Grid container sx={{ mt: '50px' }} spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ padding: '15px' }}>
              <CardHeader
                title="Upload contract"
                sx={{
                  padding: '0px',
                }}
              />
              <Box sx={{ mb: '10px', pt: '5px' }} dir="ltr">
                <Typography variant="p">Please upload your Solidity Smart Contract file below</Typography>
              </Box>
              {/* <Typography variant="p">Project Name:</Typography>
      <TextField /> */}
              <DropzoneArea
                acceptedFiles={['.sol']}
                dropzoneText="Drag and drop a Solidity Smart Contract file here or click"
                filesLimit={1}
                onChange={(files) => {
                  if (!files.length) return;
                  const file = files[0];
                  const read = new FileReader();
                  const fileName = file.name;

                  console.log('fileNane is' , fileName)
                  setFileName(fileName)

                  read.readAsBinaryString(file);

                  read.onloadend = function () {
                    console.log(read.result);
                    setFileName(fileName)
                    setFileUploaded(read.result);
                  };
                }}
              />
              <Box sx={{ display: 'flex', width: '100%', mt: '30px', justifyContent: 'center' }}>
                <Button
                  loading={loading}
                  sx={{
                    width: '100%',
                    background: `-webkit-linear-gradient(left, #60c657, #35aee2)`,
                    backgroundSize: `200% 200%`,
                    animation: `gradient-animation 4s ease infinite`,
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    scan(fileUploaded, fileName);
                    reports();
                  }}
                >
                  Start Scan
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4} style={{display: 'none'}}>
            <Card sx={{ padding: '15px' }}>
              <CardHeader title="Overview" sx={{ pb: '30px' }} />

              <Box sx={{ width: '100%', mb: '20px' }}>
                <Typography variant="p" sx={{ mb: '10px' }}>
                  Critical - {state.high}
                </Typography>
                <LinearProgress variant="determinate" value="0" />
              </Box>
              <Box sx={{ width: '100%', mb: '20px' }}>
                <Typography variant="p" sx={{ mb: '10px' }}>
                  High - 0
                </Typography>

                <LinearProgress variant="determinate" value="0" />
              </Box>

              <Box sx={{ width: '100%', mb: '20px' }}>
                <Typography variant="p" sx={{ mb: '10px' }}>
                  Medium - 0
                </Typography>

                <LinearProgress variant="determinate" value="0" />
              </Box>
              <Box sx={{ width: '100%', mb: '20px' }}>
                <Typography variant="p" sx={{ mb: '10px' }}>
                  Low - 0
                </Typography>

                <LinearProgress variant="determinate" value="0" />
              </Box>

              <Box sx={{ width: '100%', mb: '20px' }}>
                <Typography variant="p" sx={{ mb: '10px' }}>
                  Info - 0
                </Typography>

                <LinearProgress variant="determinate" value="0" />
              </Box>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
           
          
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
        <Backdrop sx={{ color: '#fff' }} open={loading}>

          <Loader/>
        </Backdrop>
      </Container>
    </Page>
  );
}

export default withAuthentication(DashboardApp);