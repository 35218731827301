import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  animatedText: {
    marginTop: '20px',
    fontSize: '20px',
    animation: `$loadingEffect 2s ${theme.transitions.easing.easeInOut} infinite`,
  },
  "@keyframes loadingEffect": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  }
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <div className={classes.animatedText}>Smart Contract Scan Is In Progress..</div>
    </div>
  );
}
