import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArchitectureIcon from '@material-ui/icons/Archive';
import SecurityIcon from '@material-ui/icons/Security';
import StorageIcon from '@material-ui/icons/Storage';
import BlockIcon from '@material-ui/icons/Block';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import ExTable from "./ExTable";
import IssueList from "./IssueList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  logo: {
    width: 300,
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 30,
  },
  categoryCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  categoryIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const ReportSummary = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    console.log(location.state.results);
  }, [location.state.results]);

  const categories = [
    { title: "Architecture and Design", icon: <ArchitectureIcon />, data: location.state.architectureAndDesign },
    { title: "Access Control", icon: <SecurityIcon />, data: location.state.accessControl },
    { title: "Blockchain Data", icon: <StorageIcon />, data: location.state.blockchainData },
    { title: "Denial of Services", icon: <BlockIcon />, data: { issues: [] } },
    { title: "Best Practices", icon: <ThumbUpIcon />, data: { issues: [] } },
  ];

  return (
    <Box className={classes.root}>
      <img
        src="https://buildyourblocks.com/wp-content/uploads/2022/04/buildyourblocks.svg"
        alt="Build Your Blocks Logo"
        className={classes.logo}
      />
      <Typography variant="h4" className={classes.title}>
        Build Your Blocks - Scan Report Summary
      </Typography>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" className={classes.cardTitle}>
            <AssessmentIcon className={classes.icon} />
            Scan Results
          </Typography>
          <ExTable results={location.state.results} />
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" className={classes.cardTitle}>
            Additional Testing Categories
          </Typography>
          <Typography variant="body1" paragraph>
            The risks are categorized into the following categories. Each section includes the description of the risks that were found in your smart contract.
          </Typography>
          <Grid container spacing={3}>
            {categories.map((category, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper className={classes.categoryCard}>
                  <CardContent>
                    <Typography variant="h6" className={classes.categoryTitle}>
                      {React.cloneElement(category.icon, { className: classes.categoryIcon })}
                      {category.title}
                    </Typography>
                    <IssueList issues={category.data.issues || []} />
                  </CardContent>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReportSummary;