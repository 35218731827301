import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { userAtom } from '../../state/index';

const withAuthentication = (WrappedComponent) => {
  const AuthenticatedComponent = (props) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [user, setUser] = useAtom(userAtom);

    useEffect(() => {
      let isMounted = true;

      const checkAuth = async () => {
        if (!token) {
          setLoading(false);
          navigate('/login', { replace: true });
          return;
        }

        try {
          const response = await fetch('https://solidity-scanner.onrender.com/api/checkAuth', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (!isMounted) return;

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.message || 'Authentication failed');
          }

          if (!data.authenticated) {
            localStorage.removeItem('token');
            navigate('/login', { replace: true });
            return;
          }

          setUser({
            id: data.user.id,
            username: data.user.username,
            email: data.user.email,
            availableScans: data.user.availableScans,
          });
          
        } catch (error) {
          console.error('Authentication error:', error);
          localStorage.removeItem('token');
          if (isMounted) {
            navigate('/login', { replace: true });
          }
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      };

      checkAuth();

      return () => {
        isMounted = false;
      };
    }, [token, navigate, setUser]);

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
      );
    }

    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};

export default withAuthentication;