import { useAtom } from "jotai";
import { useState, useCallback, useEffect } from "react";
import { userAtom } from "../state";

export const useScanResults = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');  
    const [userReports, setReports ] = useState([]);
    const [user, setUser] = useAtom(userAtom);

    
    const scan = useCallback(async (file, fileName) => {
        setLoading(true);
        setUser({
            ...user,
            loading: true,
        });
        let data;
        console.log('whats file', typeof file);
        try {
        const reqBody = {
            source: file,
        };

        const response = await fetch('https://asihota.pythonanywhere.com/analyze', {
            method: 'POST',
            body: JSON.stringify(reqBody),
            headers: {
            'Content-Type': 'application/json',
            
            },
        });
        data = await response.json();
        setResults(data);
        } catch (error) {
        setError(error);
        } finally {
        setLoading(false);
        setUser({
            ...user,
            availableScans: user.availableScans - 1,
            loading: false,
        });
        }

        // make another POST request to https://solidity-scanner.onrender.com/scan and send data to the backend 
        // the request body should be {result: data}
        const reqBody = {
            result: data,
            fileName,
        }
        const response = await fetch('https://solidity-scanner.onrender.com/scan', {
            method: 'POST',
            body: JSON.stringify(reqBody),
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        });
        const data2 = await response.json();
        console.log('data2', data2);
    }, []);

    const reports = useCallback(async () => {
        // Make get to /scanResults to fetch and log response
        const response = await fetch('https://solidity-scanner.onrender.com/userScans', {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        });
        const data = await response.json();
        // map through each item and just inculde the `result` property
        // setResults(data);
        console.log('data is', data)
         // map through each item and include both the `result` and `contractName` properties
    const results = data.map((item) => ({
        result: item.result[0]?.result,
        contractName: item?.contractName ? item.contractName : '',
    })).filter((item) => item.result !== undefined) // filter out items without a result
        setReports(results);
    }, [

    ]);

    useEffect(() => {
        reports();
    }, [
        token
    ]);
    
    
    return { results, loading, error, scan, reports: userReports };
}