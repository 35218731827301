// jotai atoms for user

import { atom } from 'jotai'

export const userAtom = atom({
    id: '',
    email: '',
    name: '',
    token: '',
    isAuthenticated: false,
    loading: true,
    error: null,
})

