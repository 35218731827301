import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import NavSection from '../components/NavSection';
import navConfig from '../layouts/dashboard/NavConfig';
import { userAtom } from '../state';
import withAuthentication from '../sections/auth/withAuthentication';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    height: '100vh',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

  const pricingPlans = [
    {
      title: 'On Demand',
      plan: 'basic',
      description: 'Try out the basic features of the product. Get a detailed report with prompts describing detected issues and remediation solutions.',
      price: '$29.99/On Demand',
      scans: '2 Scans',
    },
    {
      title: 'Enterprise',
      plan: 'premium',
      description: 'Senior Developer or Senior Security Officer or experienced NFT Enthusiast.',
      price: '$149.99/Monthly',
      scans: '40 Scans',
    },
    {
      title: 'Pro',
      plan: 'enterprise',
      description: 'Large Enterprise',
      price: '$249.99/Monthly',
      scans: '80 Scans',
    },
  ];

// ----------------------------------------------------------------------

function PricingPage() {
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem('token'));
  const [user] = useAtom(userAtom);

  
console.log('current user id is ', user.id)
  const mdUp = useResponsive('up', 'md');

  const filterNavItems = navConfig.filter((item) => item.title === 'login' || item.title === 'Pricing');

  const handleSelectPlan = async (productType) => {
    try {
      const response = await fetch('https://solidity-scanner.onrender.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productType, userId: user.id  })
      });

      const data = await response.json();

      if (response.ok) {
        // Redirect user to Stripe Checkout
        window.location.href = data.url;
      } else {
        throw new Error(data.error || 'Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      alert('Failed to initiate checkout process.');
    }
  };

  // useEffect(() => {
  //   if (!token) {
  //     navigate('/dashboard/app');
  //   }
  // }, [navigate, token]);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ maxWidth: '200px' }}>
            <Logo />
          </Box>

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 }, display: 'none' }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 15, mb: 5, textAlign: 'center' }}>
              BYB Smart Contract Scanner
            </Typography>
            <Box
              sx={{
                width: '50%',
                borderRadius: '6px',
                overflow: 'hidden', // Add this to clip the image
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: 0,
              }}
            >
              <img
                src="/landing_2.png"
                alt="login"
                style={{
                  borderRadius: '6px',
                  display: 'block', // Ensures there's no extra space at the bottom due to line-height
                }}
              />
            </Box>

            <NavSection navConfig={filterNavItems} />

          </SectionStyle>
        )}

        <Container maxWidth="lg">
        
    <Box sx={{padding: '32px'}}>
      <Box sx={{display: 'flex', justifyContent: 'center', pb: '150px', flexWrap: 'wrap'}}>
    <Typography variant="h2" gutterBottom>
      Pricing
    </Typography>
    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
    <Typography>
      A Pricing Plan for Every Budget and Business
    </Typography>
    </Box>
    </Box>
    <Grid container spacing={2} justifyContent="center">
    {pricingPlans.map((plan, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ padding: 2, minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {plan.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {plan.description}
                    </Typography>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {plan.price}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {plan.scans}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleSelectPlan(plan.plan.toLowerCase())} 
                    >
                      Choose Plan
                    </Button>
                  </Paper>
                </Grid>
              ))}
    </Grid>
    </Box>
  
        </Container>
      </RootStyle>
    </Page>
  );
}

export default withAuthentication(PricingPage);
