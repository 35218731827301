import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useScanResults } from "../../hooks/useScanResults";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: "1.2rem",
  fontWeight: "bold",
}));

const ReportRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const severityColors = {
  High: (theme) => theme.palette.error.main,
  Medium: (theme) => theme.palette.warning.main,
  Low: (theme) => theme.palette.success.main,
};

const StyledChip = styled(Chip)(({ theme, severity }) => ({
  backgroundColor: severityColors[severity]?.(theme) || theme.palette.success.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

export const UserReports = () => {
  const [openId, setOpenId] = useState(-1);
  const { reports } = useScanResults();

  if (!reports) return null;

  const getSeverity = (category) => {
    console.log('category is', category)
    if (category.toLowerCase().includes("high")) return "High";
    if (category.toLowerCase().includes("medium")) return "Medium";
    return "Low";
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <HeaderCell colSpan={2}>Scanned Report History</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.filter(report => Array.isArray(report?.result)).map((report, index) => (
            <React.Fragment key={index}>
              <ReportRow>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => setOpenId(openId === index ? -1 : index)}
                  >
                    {openId === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {report?.contractName && report.contractName.length > 0 
                      ? report.contractName 
                      : `Report ${index + 1}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {`${report.result.length} issue${report.result.length !== 1 ? 's' : ''} found`}
                  </Typography>
                </TableCell>
              </ReportRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                  <Collapse in={openId === index} timeout="auto" unmountOnExit>
                    <Box margin={2}>
                      <Table size="small" aria-label="issues">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Issue</TableCell>
                            <TableCell align="right">Line</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.result.map((row, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                <StyledChip
                                  label={getSeverity(row.category)}
                                  severity={getSeverity(row.category)}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">{row.issue}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                  {row.potential_solution}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">{row.line_number}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};