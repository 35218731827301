// 1. Architecture:
export function compareVersions(version1, version2) {
   const v1 = version1.split(".").map(Number);
  const v2 = version2.split(".").map(Number);
  // unary operator ignore
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    if (v1[i] !== v2[i]) {
      return false;
    }
  }
  return true;
}

// 2. Access Control:
export function hasWithdrawFunctionWithModifier(code) {
    // Define the regular expressions to search for the withdraw function and the whenNotPaused modifier
    const withdrawFunctionRegex = /function withdraw\(\)/;
    const whenNotPausedModifierRegex = /modifier whenNotPaused\(\)/;
  
    // Search for the withdraw function and the whenNotPaused modifier in the code
    const withdrawFunctionMatch = code.match(withdrawFunctionRegex);
    const whenNotPausedModifierMatch = code.match(whenNotPausedModifierRegex);
  
    // Check if the withdraw function and the whenNotPaused modifier were found
    if (withdrawFunctionMatch !== null || whenNotPausedModifierMatch !== null) {
      // Extract the function body and the modifier body
    //   console.log('func body', withdrawFunctionMatch);
    //   const functionBody = code.substring(withdrawFunctionMatch.index);
    //   const modifierBody = code.substring(whenNotPausedModifierMatch.index);
  
    //   // Check if the function has the whenNotPaused modifier applied
    //   const hasModifier = functionBody.includes("whenNotPaused");
  
      // Return true if the function has the whenNotPaused modifier applied
      return true
      // unnecessary else
      // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  }
  
  export function hasBlockTimestamp(code) {
    // Define the regular expression to search for the "block.timestamp" string
    const blockTimestampRegex = /block\.timestamp/;
  
    // Search for the "block.timestamp" string in the code
    const blockTimestampMatch = code.match(blockTimestampRegex);
  
    // Check if the "block.timestamp" string was found
    if (blockTimestampMatch !== null) {
      return "block.timestamp is used in this contract - do not use this in any critical functions";
    } 
    return "";
    
  }
  
