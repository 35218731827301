import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import NavSection from '../components/NavSection';
import navConfig from '../layouts/dashboard/NavConfig';
import PricingPage from './Pricing';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem('token'));

  const mdUp = useResponsive('up', 'md');

  const filterNavItems = navConfig.filter((item) => item.title === 'login' || item.title === 'Pricing');

  useEffect(() => {
    if (token) {
      navigate('/dashboard/app');
    }
  }, [token]);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ maxWidth: '200px' }}>
            <Logo />
          </Box>

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 }, display: 'none' }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 15, mb: 5, textAlign: 'center' }}>
              BYB Smart Contract Scanner
            </Typography>
            <Box
              sx={{
                width: '50%',
                borderRadius: '6px',
                overflow: 'hidden', // Add this to clip the image
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: 0,
              }}
            >
              <img
                src="/landing_2.png"
                alt="login"
                style={{
                  borderRadius: '6px',
                  display: 'block', // Ensures there's no extra space at the bottom due to line-height
                }}
              />
            </Box>

            <NavSection navConfig={filterNavItems} />

          </SectionStyle>
        )}

        <Container maxWidth="sm">
          { /* if route is /pricing show the pricing page */ 
          window.location.pathname === '/pricing' ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
              <PricingPage />
            </Box>
          ) :           <ContentStyle>
          <Typography variant="h4" gutterBottom>
            Sign in to the Build Your Blocks Solidity Scanner
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

          <AuthSocial />

          <LoginForm />

          {!smUp && (
            <Typography variant="body2" align="center" sx={{ mt: 3, display: 'none' }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )}
        </ContentStyle>}

        </Container>
      </RootStyle>
    </Page>
  );
}
